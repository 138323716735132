<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE
          {{this.purpose_edit==='lessee'?'LESSEE':
          (this.purpose_edit==='amount'?'RENTAL AMOUNT':'')}} IN LOT#
          {{this.room_details.room_no}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-flex xs12 md4 v-if="purpose_edit==='lessee'">
          <v-text-field
            v-if="room_details.member_id===null||is_change_member"
            class="mx-2 mt-5"
            v-model="date_registered"
            label="Date Registered"
            dense
            outlined
            type="date"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            v-else
            class="mx-2 mt-5"
            v-model="date_registered"
            label="Date Registered"
            dense
            outlined
            readonly
          ></v-text-field>
        </v-flex>
        <v-layout row wrap class="align-center mx-2 mt-3">
          <v-flex xs12 md4 v-if="purpose_edit==='lessee'">
            <v-checkbox v-if="room_details.member_id!=null" v-model="is_change_member"
                        label="Change Lessee?" hide-details class="me-3 mt-1"></v-checkbox>
          </v-flex>
          <v-flex xs12 md12 v-if="purpose_edit==='lessee'">
            <v-combobox
              class="mx-2"
              v-if="room_details.member_id===null||is_change_member"
              :append-icon="icons.mdiAccountSearchOutline"
              v-model="new_lessee"
              @keyup.enter="searching_search_items($event.target.value)"
              :items="search_items"
              item-value="id"
              item-text="company_name"
              placeholder="Search New Lessee"
              ref="memberCombobox"
              dense
              outlined
            ></v-combobox>
            <v-text-field
              v-else
              class="mx-2"
              v-model="name_of_lesse"
              label="Name of Lessee"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="purpose_edit==='lessee'">
            <v-text-field
              v-if="room_details.member_id===null||is_change_member"
              class="mx-2"
              v-model="total_amount_to_pay"
              label="Total Amount to Pay"
              dense
              outlined
              readonly
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="purpose_edit==='amount'">
            <v-text-field
              class="mx-2"
              v-model="room_no"
              label="Lot #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="purpose_edit==='amount'">
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Rental Amount"
              dense
              outlined
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col class="mb-4" cols="12" md="12" v-if="(purpose_edit==='lessee'&&(room_details.member_id===null||is_change_member))||purpose_edit==='amount'">
            <v-btn
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Update Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiEyeCircle
    , mdiAccountSearchOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ListOfBranches from '@/views/register_interface/branch_component/ListOfBranches'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      is_edit_room: false,
      is_change_member: false,

      date_registered: '',
      name_of_lesse: '',
      contact_person: '',
      address: '',
      type_of_lessee: '',
      type_of_business: '',
      contact_no: '',
      room_counter: '',
      room_no: '',
      electric_type: '',
      electric_amount_per_appliances: '',
      water_type: '',
      water_amount: '',

      no_of_deposits: '',
      no_of_advance: '',
      total_amount_to_pay: '',


      amount: '0',
      new_lessee: '',
      search_items: [],
    }
  }
  export default {
    props: {
      room_details: Object,
      purpose_edit: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
          , mdiAccountSearchOutline
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (this.room_details != null) {
        this.total_amount_to_pay = parseFloat(this.room_details.amount)+''
        this.room_no = this.room_details.room_no
        this.amount = this.room_details.amount
      }
      if (this.room_details.member_id != null) {
        this.date_registered = this.room_details.date_registered
        this.name_of_lesse = this.room_details.members_information.company_name

      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_data', ['edit_room_details']),
      ...mapActions('members_information', ['search_lessee']),
      searching_search_items(value) {
        this.search_lessee({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (parseFloat(this.room_details.amount) === 0 && this.purpose_edit === 'lessee') {
          this.alert = true
          this.alert_message = 'Please Change Rental Amount First!'
          this.saving_data = false
        } else {
          var proceed = true;
          if (this.new_lessee != null && this.purpose_edit === 'lessee') {
            if (this.new_lessee.id === undefined) {
              this.alert = true
              this.alert_message = 'Please Search Lessee First'
              this.saving_data = false
              proceed = false
            }
          }
          if (proceed) {
            if (this.$refs.form2.validate()) {
              const data = new FormData()
              data.append('id', this.room_details.id);
              data.append('purpose_edit', this.purpose_edit);
              data.append('amount', this.amount);
              data.append('room_no', this.room_no);
              data.append('electric_type', this.electric_type);
              data.append('electric_amount_per_appliances', this.electric_amount_per_appliances);
              data.append('water_type', this.water_type);
              data.append('water_amount', this.water_amount);
              data.append('wifi_amount', 0);
              data.append('date_registered', this.date_registered);
              data.append('member_id', this.new_lessee === null ? '' : this.new_lessee.id);
              if (this.purpose_edit === 'lessee') {
                var splitedad = this.no_of_advance.split(' ')
                var spliteddep = this.no_of_deposits.split(' ')
                data.append('no_of_advance', parseInt(splitedad[0]));
                data.append('no_of_deposits', parseInt(spliteddep[0]));
              }
              this.edit_room_details(data)
                .then(response => {
                  var color = 'error'
                  if (response.status === 200) {
                    color = 'success'
                    this.$emit('data', true)
                  } else {
                    this.alert = true
                    this.alert_message = response.data
                    this.saving_data = false
                  }
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                  this.saving_data = false
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving_data = false
            }
          }
        }
      },
    },
  }
</script>
