<template>
  <div>
    <v-card class="mt-9">
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Lot Data in
              {{(selected_branch!=null&&selected_branch!=undefined)?selected_branch.branch_code:''}}
              Branch
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.room_no }}
            </td>
            <td>
              {{ item.date_registered }}
            </td>
            <td>
              <div v-if="item.members_information===null">
                <v-chip color="success">
                  LOT IS AVAILABLE
                </v-chip>
              </div>
              <div v-else>
                {{ item.members_information.company_name }}
              </div>
            </td>
            <td>
              {{ item.amount }}
            </td>
            <td class="text-center">
              <v-chip v-if="item.is_active===1" color="success">
                ACTIVE
              </v-chip>
              <v-chip v-if="item.is_active===0" color="error">
                IN-ACTIVE
              </v-chip>
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.members_information===null && item.amount>0"
                class="mr-2"
                color="info"
                @click="edit_rooms(item,'lessee')"
              >
                {{icons.mdiPencil}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.members_information!=null"
                class="mr-2"
                color="warning"
                @click="remove_lessee(item)"
              >
                {{icons.mdiTransitTransfer}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                @click="edit_rooms(item,'amount')"
              >
                {{icons.mdiCash100}}
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_edit_room" max-width="50%">
      <room-details :key="this.key" :room_details="this.selected_room"
                    :purpose_edit="this.purpose_edit"
                    v-on:data="on_save_details"/>
    </v-dialog>

    <v-dialog v-model="is_remove_lessee" max-width="30%">
      <v-card>
        <v-card-text>
          <v-container>
            <h3 class="font-weight-regular">Choose option for security deposit disburse</h3>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-layout row wrap class="align-center mx-2">
            <v-flex xs12 md5>
              <v-btn class="w-full" color="info" @click="remove_lessee_action(1)">Refund</v-btn>
            </v-flex>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-flex xs12 md5>
              <v-btn class="w-full" color="warning" @click="remove_lessee_action(2)">Consume</v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiCash100
    , mdiTransitTransfer
    , mdiPencil
    , mdiPlusCircle
    , mdiWifi
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import RoomDetails from '@/views/register_interface/branch_component/RoomDetails'

  const initialState = () => {
    return {
      key: 0,
      is_edit_room: false,
      is_remove_lessee: false,
      selected_room: {},
      purpose_edit: '',
      data_items: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Lot #', value: 'room_no', sortable: true},
        {text: 'Date Registered', value: 'date_registered', sortable: true},
        {text: 'Lessee', value: 'members_infomation', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Status', align: 'center', value: 'is_active', sortable: true},
        {text: 'Assign Lessee', align: 'center', value: 'action', sortable: false},
        {text: 'Remove Lessee', align: 'center', value: 'action', sortable: false},
        {text: 'Change Rental Amount', align: 'center', value: 'action', sortable: false},
      ],
    }
  }
  export default {
    props: {
      selected_branch: Object,
    },
    components: {
      snackBarDialog,
      RoomDetails,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiCash100
          , mdiTransitTransfer
          , mdiPencil
          , mdiPlusCircle
          , mdiWifi
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_data', ['list_or_branch_room', 'remove_lessee_in_room', 'update_appliances_room', 'update_wifi_charges']),
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.selected_branch.id);
        this.list_or_branch_room(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('date_registered', this.date_registered);
          data.append('room_counter', this.room_counter);
          data.append('branch_code', this.branch_code.toUpperCase());
          data.append('address', this.address.toUpperCase());
          data.append('contact_no', this.contact_no);
          data.append('contact_person', this.contact_person.toUpperCase());
          this.create_branch(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
                Object.assign(this.$data, initialState())
                this.initialize_data()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      edit_rooms(item, purpose) {
        this.key++
        this.purpose_edit = purpose
        this.selected_room = item
        this.is_edit_room = true;
        this.is_remove_lessee = false;
      },
      remove_lessee(item) {
        this.key++
        this.selected_room = item
        if (confirm('Are you sure to remove lessee in this lot?')) {
          this.is_remove_lessee = true
          this.is_edit_room = false;
        }
      },
      remove_lessee_action(item) {
        this.key++
        if (confirm('Are you sure to remove lessee in this lot?')) {
          this.is_remove_lessee = true
          this.is_edit_room = false;
          const data = new FormData()
          data.append('id', this.selected_room.id);
          data.append('member_id', this.selected_room.member_id);
          data.append('is_consumed', item === 2 ? 1 : 0);
          data.append('is_refunded', item === 1 ? 1 : 0);
          this.remove_lessee_in_room(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
                Object.assign(this.$data, initialState())
                this.initialize_data()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      add_appliances(item) {
        this.key++
        this.selected_room = item
        let appliances_counter = prompt("How many appliances?", "1");
        const data = new FormData()
        data.append('id', item.id);
        data.append('appliances_counter', appliances_counter);
        this.update_appliances_room(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
              Object.assign(this.$data, initialState())
              this.initialize_data()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      add_wifi_amount(item) {
        this.key++
        this.selected_room = item
        let appliances_counter = prompt("How much wifi charges?", "1");
        const data = new FormData()
        data.append('id', item.id);
        data.append('wifi_amount', appliances_counter);
        this.update_wifi_charges(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
              Object.assign(this.$data, initialState())
              this.initialize_data()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      on_save_details() {
        this.purpose_edit = ''
        this.selected_room = {}
        this.is_edit_room = false;
        this.is_remove_lessee = false;
        this.initialize_data()
      }
    },
  }
</script>
